import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import AboutComponent from "../components/about";

const About = ({ data }) => {
  const {
    allContentfulAbout: { nodes: abouts },
  } = data;

  return (
    <Layout>
      <Seo
        title="Qui sommes-nous"
        description="Notre Mission, Notre Vision, Nos Valeurs"
      />
      <AboutComponent abouts={abouts} />
    </Layout>
  );
};

export const query = graphql`
  {
    allContentfulAbout(sort: { order: ASC, fields: createdAt }) {
      nodes {
        content {
          content
        }
        featured
        tags
        title
        image {
          gatsbyImageData(
            layout: FULL_WIDTH
            width: 100
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }
  }
`;
export default About;
